import clsx from 'clsx';
import { FC } from 'react';

import { Obfuscate } from '_components/common/Obfuscate/Obfuscate';
import { getLocalizedUrl } from '_core/utils/public-links/getLocalizedUrl';
import { AnchorType } from '_types/public-api';

type Props = {
  links?: AnchorType[];
  className?: string;
};

export const DirectoriesList: FC<Props> = (props) => {
  const { links, className } = props;

  return (
    <ul className={clsx('list-reset small', className)}>
      {links?.map((levelTwo, key) => {
        const localizedLink = getLocalizedUrl(levelTwo);
        return (
          <li className="mb-8 top-link-anchor" key={key}>
            <Obfuscate href={localizedLink} obfuscate>
              {levelTwo.anchor}
            </Obfuscate>
          </li>
        );
      })}
    </ul>
  );
};
