import Image from 'next/image';
import { ReactElement } from 'react';

import { GetUrlType } from '_core/links/public-app-links';

const s3BucketUrl = process.env.NEXT_PUBLIC_S3_BUCKET;

export type AdviceProps = {
  title: string;
  description: string;
  learnMore: string;
  imgAlt: string;
  briefingAdviceCTA: ReactElement;
  getUrl: GetUrlType;
};

export const GetAdvice = (props: AdviceProps) => {
  const { title, description, learnMore, briefingAdviceCTA, imgAlt, getUrl } = props;

  return (
    <div className="fadeIn layout-row flex">
      <div className="flex-45 layout-column px-8">
        <div className="p-16 flex layout-column">
          <p className="h6 bold">{title}</p>
          <p className="mt-8 small text-secondary-500">{description}</p>
          <a
            data-testid="navbar-advice-learn-more-cta"
            href={getUrl('client.advisors')}
            className="mt-16 display-block small cursor-pointer text-secondary-500 text-underline">
            {learnMore}
          </a>
        </div>
        <div className="p-16">{briefingAdviceCTA}</div>
      </div>
      <div className="layout-column layout-align-center-start flex px-8 relative">
        <Image
          src={`${s3BucketUrl}/illustrations/advisors/advisor-laptop.webp`}
          alt={imgAlt}
          fill
          style={{ objectFit: 'contain' }}
        />
      </div>
    </div>
  );
};
