import {
  CampaignTwoTone,
  DesignServicesTwoTone,
  DevicesTwoTone,
  FormatShapesTwoTone,
  LocationCityTwoTone,
  LocationOnTwoTone,
} from '@mui/icons-material';
import clsx from 'clsx';
import { FC } from 'react';

import { IconNames } from '_core/type-utils';

type Props = {
  iconName?: IconNames;
  height?: number;
  width?: number;
  className?: string;
};
// It's not dynamic, but we don't want it dynamic at least for now as is impacting performances
// you can find the revert here: https://github.com/sortlist/javascript-labs/pull/3923/commits/90e7474ca190e698432944d1eb1b9071ed74f261
export const DynamicIcon: FC<Props> = (props) => {
  const { iconName, className, height, width } = props;

  const getIcon = () => {
    const iconProps = { className: clsx(className), style: { height, width, fontSize: width } };
    switch (iconName) {
      case 'LocationOnTwoTone':
        return <LocationOnTwoTone {...iconProps} />;
      case 'CampaignTwoTone':
        return <CampaignTwoTone {...iconProps} />;
      case 'DevicesTwoTone':
        return <DevicesTwoTone {...iconProps} />;
      case 'DesignServicesTwoTone':
        return <DesignServicesTwoTone {...iconProps} />;
      case 'LocationCityTwoTone':
        return <LocationCityTwoTone {...iconProps} />;
      case 'FormatShapesTwoTone':
        return <FormatShapesTwoTone {...iconProps} />;
    }
    return <DesignServicesTwoTone {...iconProps} />;
  };

  return <>{getIcon()}</>;
};
