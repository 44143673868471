import { FC, ForwardedRef, ReactNode } from 'react';

type MainProps = {
  children: ReactNode;
  className?: string;
  id: string;
  scrollableCtn?: ForwardedRef<HTMLDivElement>;
};

export const Main: FC<MainProps> = (props) => {
  const { children, className, id, scrollableCtn, ...p } = props;

  return (
    <div className="flex relative" {...p}>
      <div
        id={id}
        ref={scrollableCtn}
        className={'overflow-auto overflow-y-scroll display-block flex-grow layout-fill lazyload-scroll-container'}>
        {children}
      </div>
    </div>
  );
};
