'use client';

import { ArrowDropDownRounded, ArrowDropUpRounded, ArrowForward } from '@mui/icons-material';
import { useOnClickOutside } from '@sortlist-frontend/design-system';
import { Button } from '@sortlist-frontend/design-system/ssr';
import { useDebounceCallback } from '@sortlist-frontend/utils';
import { Fragment, ReactElement, useRef, useState } from 'react';

type SectionItemId = 'explore' | 'post-a-project' | 'advice';

type SectionItem = {
  id: SectionItemId;
  title: string;
  subtitle: string;
  dataTestid: string;
};

type Props = {
  items: SectionItem[];
  label: string | ReactElement;
  showArrow?: boolean;
  getAdvice: ReactElement;
  postAProject: ReactElement;
  explore: ReactElement;
};

export const FindAgencies = (props: Props) => {
  const { items, label, showArrow = true, postAProject, explore, getAdvice } = props;
  const [open, setOpen] = useState(false);
  const [activePanel, setActivePanel] = useState<SectionItemId>(items[0].id);
  const menuRef = useRef(null);

  const openMenu = useDebounceCallback((open: boolean) => setOpen(open), 20);

  useOnClickOutside(menuRef, () => {
    openMenu(false);
  });

  return (
    <div>
      <Button
        data-testid="navbar-find-agencies"
        animation="none"
        size="sm"
        iconSize="lg"
        className="flex-none small px-4"
        buttonVariant="default"
        buttonStyle="secondary"
        onClick={() => openMenu(!open)}
        id="find-agencies-btn">
        <Fragment>
          {label}
          {showArrow ? (
            open ? (
              <ArrowDropUpRounded className="ml-8" />
            ) : (
              <ArrowDropDownRounded className="ml-8" />
            )
          ) : undefined}
        </Fragment>
      </Button>

      {open ? (
        <div
          ref={menuRef}
          className="find-agencies-menu absolute bg-neutral-100 layout-row layout-align-center-center shadow-4">
          <div className="container-lg px-0 py-24 layout-row">
            <div className="layout-column layout-align-start-center flex-30 px-16 border-right border-secondary-300">
              {items.map((item) => (
                <div
                  data-testid={item.dataTestid}
                  className={`section-title width-100 p-16 rounded-lg cursor-pointer ${activePanel === item.id ? 'active' : ''}`}
                  key={item.id}
                  onMouseEnter={() => {
                    setActivePanel(item.id);
                  }}>
                  <div className=" layout-row layout-align-space-between-center">
                    <p className="h6 bold">{item.title}</p>
                    <ArrowForward className="arrow-forward text-primary-300 hide" style={{ fontSize: 16 }} />
                  </div>
                  <p className="mt-8 small text-secondary-500">{item.subtitle}</p>
                </div>
              ))}
            </div>
            {activePanel === 'advice' ? getAdvice : null}
            {activePanel === 'post-a-project' ? postAProject : null}
            {activePanel === 'explore' ? explore : null}
          </div>
        </div>
      ) : null}
    </div>
  );
};
