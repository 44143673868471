import { Levels } from '_core/utils/public-links/_types';
import { Link, Links } from '_types/public-api';

export const categoryFiltered = (links: Links | undefined, category: string) =>
  links?.data.filter((link) => link?.attributes?.category === category);

export const sortLevels = (levels: Levels): Levels | undefined => {
  const sortedLevels: Levels = {};

  Object.keys(levels).forEach((category) => {
    const levelOne = Object.keys(levels[category]);

    levelOne.forEach((levelTwo) => {
      const levelThree = Object.keys(levels[category][levelTwo]);

      levelThree.forEach((value) => {
        const anchors = levels[category][levelTwo][value];

        if (typeof sortedLevels[category] === 'undefined') sortedLevels[category] = {};

        if (typeof sortedLevels[category][levelTwo] === 'undefined') sortedLevels[category][levelTwo] = {};

        if (typeof sortedLevels[category][levelTwo][value] === 'undefined')
          sortedLevels[category][levelTwo][value] = [];

        sortedLevels[category][levelTwo][value] = anchors.sort((firstEl, secondEl) => {
          if (firstEl.position < secondEl.position) return -1;
          return 0;
        });
      });
    });
  });
  return sortedLevels;
};

export const getObjIcons = (links: Link[] | undefined, category: string) => {
  const objWithIcons = {} as Record<string, string>;
  if (!links) return {};

  links
    .filter((link) => link.attributes.category === category)
    .forEach((link) => {
      const level1 = link?.attributes?.metadata?.level_1 as string;
      const level2 = link?.attributes?.metadata?.level_2 as string;
      const level3 = link?.attributes?.metadata?.level_3 as string;
      const icon = link?.attributes?.metadata?.icon_name;
      if (icon) {
        if (level1) objWithIcons[level1] = icon;
        if (level2) objWithIcons[level2] = icon;
        if (level3) objWithIcons[level3] = icon;
      }
    });
  return objWithIcons;
};
