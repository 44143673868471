import { Levels } from './_types';

export const sortLevels = (levels: Levels): Levels | undefined => {
  const sortedLevels: Levels = {};

  Object.keys(levels).forEach((category) => {
    const countries = Object.keys(levels[category]);

    countries.forEach((country) => {
      const cities = Object.keys(levels[category][country]);

      cities.forEach((city) => {
        const anchors = levels[category][country][city];

        if (typeof sortedLevels[category] === 'undefined') sortedLevels[category] = {};

        if (typeof sortedLevels[category][country] === 'undefined') sortedLevels[category][country] = {};

        if (typeof sortedLevels[category][country][city] === 'undefined') sortedLevels[category][country][city] = [];

        sortedLevels[category][country][city] = anchors.sort((firstEl, secondEl) => {
          if (firstEl.position < secondEl.position) return -1;
          return 0;
        });
      });
    });
  });
  return sortedLevels;
};
