import { useQuery } from '@tanstack/react-query';
import { AxiosRequestConfig } from 'axios';

import { api } from '_core/api/api-nextjs-proxy';
import { usePublicAppContext } from '_core/context/public-app-context';

export type Expertise = {
  expertiseId: number;
  name: string;
  slug?: string;
  title?: string;
  created_at?: string;
  updated_at?: string;
  category?: string;
  uuid?: string;
};

export const cacheKey = {
  expertises: (locale: string) => `/api/proxy/expertises/all-expertises?locale=${locale}`,
};

const urls = {
  expertises: (locale: string) => `/api/proxy/expertises/all-expertises?locale=${locale}`,
};

export const expertisesRepo = {
  getExpertises: async (config?: AxiosRequestConfig): Promise<Expertise[]> => {
    const { locale = 'en' } = config?.headers || {};
    const returnObject = await api.get(urls.expertises(locale), config);
    return returnObject?.data?.data?.expertises;
  },
};

export const useExpertises = () => {
  const { domainInfo, locale: appLocale } = usePublicAppContext();
  const baseURL = domainInfo?.getOriginUrl() ?? '';
  const locale = appLocale || 'en';
  const headers = { locale };

  return useQuery({
    queryKey: [cacheKey.expertises(locale)],
    queryFn: () => {
      return expertisesRepo.getExpertises({ baseURL, headers });
    },
  });
};
