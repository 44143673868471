'use client';

import { ArrowForward } from '@mui/icons-material';
import { splitArrayIntoTwoEqualParts } from '@sortlist-frontend/utils';
import { useMemo, useState } from 'react';

import { DomainInfo } from '_backend/integration/domain-info';
import { NavigationData } from '_components/layout/Layout';
import { Levels } from '_core/utils/public-links/_types';
import { categoryFiltered } from '_core/utils/public-links/category-filtered';
import { createLevels } from '_core/utils/public-links/createLevels';
import { services } from '_core/utils/public-links/services';
import { sortLevels } from '_core/utils/public-links/sort-levels';
import { AnchorType, Links } from '_types/public-api';

import { getUrlHelper } from '../../utils';
import { DirectoriesList } from './DirectoriesList';

type Props = {
  links?: Links;
  navigationData: NavigationData;
  title: string;
  subtitle: string;
  notWhatYouAreLookingFor: string;
  browseAllServices: string;
};

export const Explore = (props: Props) => {
  const { links, navigationData, title, subtitle, notWhatYouAreLookingFor, browseAllServices } = props;

  const domainInfo = DomainInfo.getFromOrigin(navigationData.origin);
  const getUrl = getUrlHelper(domainInfo, navigationData.locale);

  const exploreLinks = useMemo(
    () => createLevels(links, services(links, 'navbar', categoryFiltered), sortLevels),
    [links],
  );

  const levelOneLinks = Object.keys((exploreLinks || {}) as Levels).sort((a, b) => a.localeCompare(b));
  const firstLink = levelOneLinks[0];
  const [activeProvider, setActiveProvider] = useState<string>(firstLink);

  const activeProvidersLinks =
    exploreLinks &&
    (exploreLinks as Levels)[activeProvider] &&
    (exploreLinks as Levels)[activeProvider]['null']['null'];

  const [activeProvFirstColumnLinks, activeProvSecondColumnLinks] = splitArrayIntoTwoEqualParts(
    activeProvidersLinks?.sort((a, b) => a.position - b.position), // sort again because firefox/safari seems to ignore the first sort. => hydration error
  );

  return (
    <div className="layout-row">
      <div className="flex-45 layout-column px-16">
        <header className="py-16 px-8">
          <p className="h6 bold">{title}</p>
          <p className="mt-8 small text-secondary-500">{subtitle}</p>
        </header>
        <div className="py-16 px-8 layout-column layout-align-start-stretch">
          {levelOneLinks?.map((levelOne, key) => {
            return (
              <span
                key={key}
                data-testid={`navbar-explore-${key}-button`}
                className={`provider-link p-12 text-secondary-900 rounded-sm display-inline-block cursor-pointer ${activeProvider === levelOne ? 'active' : ''}`}
                onMouseEnter={() => {
                  setActiveProvider(levelOne);
                }}>
                <span className="layout-row layout-align-start-center">
                  <span className="medium pr-16 flex">{levelOne}</span>
                  <ArrowForward className="arrow-forward text-primary-300 hide" style={{ fontSize: 16 }} />
                </span>
              </span>
            );
          })}
        </div>
      </div>
      {activeProvidersLinks && activeProvidersLinks?.length > 0 && (
        <div className="flex layout-column px-16">
          <div
            data-testid={`navbar-explore-expertises`}
            className="bg-secondary-100 rounded-md layout-column px-8 flex-grow ">
            <div className="px-16 pt-16">
              <p className="h6 bold text-secondary-900">{activeProvider}</p>
            </div>
            <div className="px-8 pb-8 flex layout-column layout-align-start-stretch">
              <div className="layout-row mt-8">
                <div className="px-8 flex">
                  <DirectoriesList links={activeProvFirstColumnLinks as AnchorType[]} />
                </div>
                <div className="px-8 flex">
                  <DirectoriesList links={activeProvSecondColumnLinks as AnchorType[]} />
                </div>
              </div>
            </div>
            <div className="p-16">
              <p className="text-secondary-500 small">{notWhatYouAreLookingFor}</p>
              <a
                data-testid={`navbar-explore-browse-all-button`}
                href={getUrl('client.explore')}
                className="text-secondary-500 text-underline small">
                {browseAllServices}
              </a>
            </div>
          </div>
        </div>
      )}
    </div>
  );
};
