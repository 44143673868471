import { getStorage } from '@sortlist-frontend/utils';

import { DomainInfo } from '_backend/integration/domain-info';

type ProjectData = {
  token: string;
  creationDate: string;
  projectId: string;
};

export const getExternalResourcesUrl = (slug: string, domainInfo: DomainInfo | null, locale: string) => {
  // For ".be" /SLUG exists only on "/fr/SLUG" and "/nl/SLUG"
  // Example: "sortlist.be/fr" to "sortlist.be/fr/SLUG"
  if (domainInfo?.getIso31661() === 'BE') {
    return `${domainInfo?.getOriginUrl()}/${locale}/${slug}`;
  }

  // From ".com" with locale 'es', or others, we redirect from
  // Example: "sortlist.com/es" to "sortlist.es/SLUG"
  if (locale && !domainInfo?.getIso31661() && domainInfo?.getMainLocale() !== locale) {
    const extractDomainInfo = domainInfo?.getDomainInfoByIso31661(locale?.toUpperCase());
    if (extractDomainInfo) {
      const { url } = extractDomainInfo;
      return `${url}/${slug}`;
    }
  }

  // Main locale matches the actual locale so we just go to /SLUG
  // Example: "sortlist.es" -> "sortlist.es/SLUG"
  if (domainInfo?.getMainLocale() === locale) {
    return `${domainInfo?.getOriginUrl()}/${slug}`;
  }

  return '/' + slug;
};

export const getProjectData = () => {
  const projectDataSerialized = getStorage('projectData');
  if (!projectDataSerialized) return null;

  return JSON.parse(projectDataSerialized) as ProjectData;
};

export const isProjectBannerActive = (pathname: string) => {
  const excludedPages = ['/project', '/meet', '/claim', '/join', '/select-account'];
  const isExcludedPage = excludedPages.some((page) => pathname.includes(page));
  if (isExcludedPage) return false;

  const projectData = getProjectData();
  if (!projectData) return false;

  const { creationDate } = projectData;

  const yesterday = new Date();
  yesterday.setDate(yesterday.getDate() - 1);

  return new Date(creationDate) > yesterday;
};
