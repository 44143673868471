// Move these to dedicated file
export const deepCopyObject = <T>(object: T): T => JSON.parse(JSON.stringify(object));

/**
 * Moves an item inside an array, from one index to another.
 *
 * @param arr array of unknown elements
 * @param old_index previus index of the element that will be moved
 * @param new_index newe index of the element that will be moved
 * @returns new array with moved element
 */
export const arrayMove = (arr: unknown[], old_index: number, new_index: number) => {
  const newArray = [...arr];

  if (new_index >= newArray.length) {
    let k = new_index - newArray.length + 1;
    while (k--) {
      newArray.push(undefined);
    }
  }
  newArray.splice(new_index, 0, newArray.splice(old_index, 1)[0]);
  return newArray;
};

export const splitArrayIntoTwoEqualParts = (items?: unknown[]) => {
  let evenArray: unknown[] = [];
  let oddArray: unknown[] = [];

  if (!items) return [evenArray, oddArray];

  if (items.length > 10) {
    evenArray = items.slice(0, Math.ceil(items.length / 2));
    oddArray = items.slice(Math.ceil(items.length / 2), items.length);
  } else {
    evenArray = items;
  }

  return [evenArray, oddArray];
};
