import { TFunction } from '@sortlist-frontend/translation/ssr';

import { DomainInfo } from '_backend/integration/domain-info';
import { getExternalResourcesUrl } from '_components/layout/utils';
import { GetUrlType } from '_core/links/public-app-links';

export type MenuData = {
  href: string;
  visible: boolean;
  label: string;
  dataTestid: string;
};

export const getClientsMenuData = (t: TFunction, getUrl: GetUrlType): MenuData[] => {
  return [
    {
      href: getUrl('providers'),
      visible: true,
      label: t('common:navbar.getClientsMenu.apply_V2'),
      dataTestid: 'navbar-get-clients-apply',
    },
    {
      href: getUrl('providers.pricing'),
      visible: true,
      label: t('common:navbar.getClientsMenu.pricing'),
      dataTestid: 'navbar-get-clients-pricing',
    },
  ];
};

export const getAccountMenuData = (t: TFunction, getUrl: GetUrlType): MenuData[] => {
  return [
    {
      href: getUrl('join'),
      visible: true,
      dataTestid: 'navbar-signup',
      label: t('common:navbar.accountMenu.signup'),
    },
    {
      href: getUrl('selectLoginType'),
      visible: true,
      dataTestid: 'navbar-login',
      label: t('common:navbar.accountMenu.login'),
    },
  ];
};

export const getResourcesMenuData = (
  t: TFunction,
  getUrl: GetUrlType,
  domainInfo: DomainInfo | null,
  locale: string,
): MenuData[] => {
  return [
    {
      href: getExternalResourcesUrl('blog', domainInfo, locale),
      visible: domainInfo?.hasBlog() ? domainInfo.hasBlog() : false,
      label: t('common:navbar.resourcesMenu.blog'),
      dataTestid: 'navbar-resources-blog',
    },
    {
      href: getExternalResourcesUrl('datahub', domainInfo, locale),
      visible: domainInfo?.hasDataHub() ? domainInfo.hasDataHub() : false,
      label: t('common:navbar.resourcesMenu.datahub_v1'),
      dataTestid: 'navbar-resources-datahub',
    },
    {
      href: getUrl('stories'),
      visible: true,
      label: t('common:navbar.resourcesMenu.stories'),
      dataTestid: 'navbar-resources-stories',
    },
    {
      href: `https://help.sortlist.com/${locale}`,
      visible: true,
      label: t('common:navbar.resourcesMenu.help&Support'),
      dataTestid: 'navbar-resources-help',
    },
  ];
};

const getParam = (key: string, value?: string) => (value ? `${key}=${value}` : '');
const getQS = (params?: Record<string, string | undefined>) => {
  if (params != null && Object.keys(params).length > 0) {
    const keys = Object.keys(params);
    const query = keys.map((key) => getParam(key, params[key])).join('&');
    return query ? `?${query}` : '';
  }
  return '';
};
const getAnchor = (name?: string) => (name ? `#${name}` : '');

export const getUrlHelper = (domain: DomainInfo | null, locale: string) => {
  const prefix = domain?.getLocalizedPath(locale);
  const urls = {
    'client.advisors': `${prefix}/clients/products/advisors`,
    'client.howItWorks': `${prefix}/how-it-works/client`,
    'client.testimonials': `${prefix}/stories/client`,
    'client.explore': `${prefix}/explore`,
    'agency.claim.landing': ({ ref }: { ref?: string }) => `${prefix}/claim/agency${getQS({ ref })}`,
    'agency.claim.confirm': ({ slugOrUuid, name }: { slugOrUuid?: string; name?: string }) =>
      slugOrUuid
        ? `${prefix}/claim/agency/confirm/${slugOrUuid}${getQS({ name })}`
        : `${prefix}/claim/agency/confirm${getQS({ name })}`,
    'agency.claim.edit': ({
      claimSessionId,
      uuid,
      name,
    }: {
      claimSessionId: string;
      uuid?: string; // if not provided, will be new record
      name?: string;
    }) => {
      const url = `${prefix}/claim/agency/edit/${claimSessionId}`;
      return uuid != '' ? `${url}/${uuid}${getQS({ name })}` : `${url}${getQS({ name })}`;
    },
    'agency.search': `${prefix}/search`,
    'agency.howItWorks': `${prefix}/how-it-works/agency`,
    'providers.pricing': `${prefix}/providers/pricing`,
    'agency.testimonials': `${prefix}/stories/agency`,
    'agency.profile': ({ slug, anchor }: { slug: string; anchor?: string }) =>
      `${prefix}/agency/${slug}${getAnchor(anchor)}`,
    'agency.profile.review': ({ slug }: { slug: string }) => `${prefix}/agency/${slug}/review`,
    'agency.portfolio.workSlug': ({ slug, workSlug }: { slug: string; workSlug: string }) =>
      `${prefix}/agency/${slug}/portfolio/${workSlug}`,
    'project.new': ({ autoload, ref }: { autoload?: string; ref?: string }) =>
      `${prefix}/project/new${getQS({ autoload, ref })}`,
    join: `${prefix}/join`,
    selectLoginType: `${prefix}/select-account`,
    'legal.privacyPolicy': `${prefix}/privacy`,
    'legal.impressum': `${prefix}/impressum`,
    'legal.terms': `${prefix}/terms`,
    'info.about': `${prefix}/about`,
    'info.contact': `${prefix}/contact`,
    'info.countries': `${prefix}/countries`,
    'info.jobs': 'https://sortlist.breezy.hr/',
    'longtails.hubPage': ({ expertiseSlug }: { expertiseSlug: string }) => `${prefix}/${expertiseSlug}`,
    'info.help': `https://help.sortlist.com/${locale}`,

    // Providers
    providers: ({ ref }: { ref?: string }) => `${prefix}/providers${getQS({ ref })}`,
    'providers.visibility': `${prefix}/providers/visibility`,
    'providers.opportunity': `${prefix}/providers/opportunity`,

    // Refund
    'legal.refundPolicy': `${prefix}/providers/opportunity/refund-policy`,

    //stories
    stories: `${prefix}/stories`,
    story: ({ storySlug }: { storySlug: string }) => `${prefix}/story/${storySlug}`,

    // lobby
    lobby: ({ token }: { token: string }) => `${prefix}/project/dashboard/${token}`,
  };

  return ((key: string, params?: Record<string, string | undefined>) => {
    const result = urls[key as keyof typeof urls];

    if (typeof result === 'function') {
      return result((params != null ? params : {}) as any);
    }
    return result;
  }) as GetUrlType;
};
