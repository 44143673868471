import { DomainInfo } from '_backend/integration/domain-info';
import { Obfuscate } from '_components/common/Obfuscate/Obfuscate';
import { LayoutElementsProps } from '_components/layout/Layout';

import { getUrlHelper } from '../../utils';
import { ExpertiseList } from './ExpertiseList';

export const PostAProject = (props: LayoutElementsProps) => {
  const { t, navigationData, expertises, briefingCTA } = props;

  const domainInfo = DomainInfo.getFromOrigin(navigationData.origin);
  const locale = navigationData.locale;

  const getUrl = getUrlHelper(domainInfo, locale);

  const sortedExpertises = expertises?.sort((a, b) => a.name.localeCompare(b.name));

  const firstExpertises = sortedExpertises?.slice(0, (sortedExpertises?.length + 1) / 2);
  const secondExpertises = sortedExpertises?.slice((sortedExpertises?.length + 1) / 2, sortedExpertises?.length + 1);

  return (
    <div className="fadeIn layout-row">
      <div className="flex-45 layout-column px-16">
        <div className="py-16 px-8 flex layout-column">
          <p className="h6 bold">{t('common:navbar.findAgencies.postAProject.letAgenciesComeToYou_V2')}</p>
          <p className="small text-secondary-500 mt-8">
            {t('common:navbar.findAgencies.postAProject.describeYourNeeds_V2')}
          </p>
          <ul className="small text-secondary-500 mt-16 pl-24 mb-0">
            <li className="pb-8">{t('common:navbar.findAgencies.postAProject.simpleQuickAgile')}</li>
            <li className="pb-8">{t('common:navbar.findAgencies.postAProject.completeAndUpToDate')}</li>
            <li className="pb-8">{t('common:navbar.findAgencies.postAProject.personalAssistance')}</li>
          </ul>
          <Obfuscate
            target="_blank"
            href={getUrl('project.new')}
            className="mt-8 text-underline small text-secondary-500"
            obfuscate={true}>
            {t('common:navbar.findAgencies.postAProject.learnMore')}
          </Obfuscate>
        </div>
        <div className="py-16 px-8">{briefingCTA}</div>
      </div>
      <div className="flex layout-column px-16">
        <div
          data-testid="navbar-expertise-list"
          className="expertise-list bg-secondary-100 rounded-md layout-column px-8 flex-grow overflow-auto">
          <div className="px-16 pt-16">
            <p className="h6 bold text-secondary-900">{t('common:navbar.findAgencies.postAProject.postAProjectIn')}</p>
          </div>
          <div className="px-8 pb-8 flex layout-column layout-align-start-stretch">
            <div className="layout-row mt-8">
              <div className="px-8 flex">
                <ExpertiseList expertises={firstExpertises} />
              </div>
              <div className="px-8 flex">
                <ExpertiseList expertises={secondExpertises} />
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};
