import { DomainInfo } from '_backend/integration/domain-info';
import { Obfuscate } from '_components/common/Obfuscate/Obfuscate';
import { getUrlHelper } from '_components/layout/PrimaryNav/DesktopNav/utils';

import { FooterProps } from '..';

export const Others = (props: FooterProps) => {
  const { t, navigationData } = props;
  const { locale, origin } = navigationData;

  const domainInfo = DomainInfo.getFromOrigin(origin);
  const getUrl = getUrlHelper(domainInfo, locale);

  return (
    <ul
      data-testid="footer-bottom-links-container"
      className="small lh-3 layout-column layout-gt-sm-row layout-wrap list-reset text-secondary-700">
      <li className="text-secondary-700 layout-row">
        <span>{new Date().getFullYear()} © Sortlist</span>&nbsp;-&nbsp;
      </li>
      <li className="text-secondary-700 layout-row">
        <span className="text-secondary-700">{t('common:footer.navigation.extra.right')}</span>&nbsp;-&nbsp;
      </li>
      <li>
        <Obfuscate className="text-secondary-700" href={getUrl('legal.terms')} obfuscate={true}>
          {t('common:footer.navigation.extra.terms')}&nbsp;-&nbsp;
        </Obfuscate>
      </li>

      <li>
        <Obfuscate className="text-secondary-700" href={getUrl('legal.privacyPolicy')} obfuscate={true}>
          {t('common:footer.navigation.extra.privacy')}
        </Obfuscate>
      </li>

      {locale === 'de' && (
        <li>
          <Obfuscate className="text-secondary-700" href={getUrl('legal.impressum')} obfuscate={true}>
            &nbsp;-&nbsp;{t('common:footer.navigation.extra.impressum')}
          </Obfuscate>
        </li>
      )}
    </ul>
  );
};
