import { Button } from '@sortlist-frontend/design-system';
import { Trans, useTranslation } from '@sortlist-frontend/translation/ssr';
import { useRouter } from 'next/router';
import { Fragment, useState } from 'react';

import {
  BriefingDialog,
  OPEN_BRIEFING,
  useBriefingMessage,
} from '_components/Briefing/versions/NormalBriefing/BriefingDialog';
import { CompareMenu } from '_components/Comparator/Menu';
import { usePublicAppContext } from '_core/context/public-app-context';
import { useExpertises } from '_core/repos/expertises.repo';
import { getPublicApiRequestHomeLink, PublicApiRepo, useGetLinks } from '_core/repos/public-api.repo';

import { Layout, LayoutProps } from './Layout';
import { SearchCta } from './PrimaryNav/DesktopNav/styles';
import { ProjectBanner } from './ProjectBanner';
import { SubNav } from './SubNav';

export const LayoutPagesRouter = (props: LayoutProps) => {
  const { children, showSubnav, showRankUpdated = false, showSearch = true, ...restProps } = props;

  const { domainInfo, locale } = usePublicAppContext();
  const router = useRouter();
  const url = getPublicApiRequestHomeLink(domainInfo?.getOriginUrl() || '', locale);

  const { t } = useTranslation(['common', 'home']);
  const { data: expertises } = useExpertises();
  const { data: links } = useGetLinks(url);
  const { data: homepageLinks } = useGetLinks(PublicApiRepo.publicApiNavBarUrl);

  const [isProjectBannerShown, setIsProjectBannerShown] = useState(false);

  const { sendMessage } = useBriefingMessage();

  // TODO: Uncomment this when project banner is adapted
  // useEffect(() => {
  //   setIsProjectBannerShown(isProjectBannerActive(router.pathname));
  // }, []);

  return (
    <Fragment>
      <Layout
        showRankUpdated={showRankUpdated}
        navigationData={{ origin: domainInfo?.getOriginUrl() ?? '', locale, resolvedUrl: router.asPath }}
        expertises={expertises}
        links={links}
        t={t}
        briefingCTA={
          <Button
            data-testid="briefing-button-navbar-project"
            size="sm"
            buttonStyle="primary"
            buttonVariant="raised"
            className="m-0"
            label={t('common:navbar.menu.postAProject')}
            truncate={false}
            onClick={() => {
              sendMessage(OPEN_BRIEFING, { cta: 'navbar-need' });
            }}
          />
        }
        briefingAdviceCTA={
          <Button
            data-testid="navbar-advice-cta"
            label={
              <Trans i18nKey={'common:navbar.findAgencies.getAdvice.buttonLabel'}>
                Get advice <span className="italic">it’s free!</span>.
              </Trans>
            }
            onClick={() => {
              sendMessage(OPEN_BRIEFING, { cta: 'navbar-advice' });
            }}
            buttonStyle={'primary'}
            buttonVariant={'raised'}
            size="sm"
          />
        }
        search={
          showSearch ? (
            <SearchCta
              cta="hero"
              page="search-landing"
              element="search-landing"
              showIndustry={false}
              version="navbarDesktop"
              keywordSelectorPlaceholder={t('common:navbar.menu.whatService')}
              className="flex"
            />
          ) : null
        }
        searchMobile={
          <SearchCta
            cta="hero"
            showIndustry={false}
            page="search-landing"
            version={'navbarMobile'}
            element="search-landing"
            locationAutocompletePlaceholder={t('common:navbar.menu.where')}
            keywordSelectorPlaceholder={t('common:navbar.menu.whatService')}
          />
        }
        comparatorCTA={<CompareMenu />}
        {...restProps}>
        {/* Should be on layout actually but is not used for now. */}
        {isProjectBannerShown && <ProjectBanner setIsProjectBannerShown={setIsProjectBannerShown} />}
        {showSubnav ? <SubNav otherString={t('home:sections.catalog.others_v1')} links={homepageLinks} /> : null}
        {children}
      </Layout>
      <BriefingDialog />
    </Fragment>
  );
};
