import { FavoriteTwoTone } from '@mui/icons-material';
import { Badge as MuiBadge } from '@mui/material';
import { Tooltip, useMediaQuery } from '@sortlist-frontend/design-system';
import { useTranslation } from '@sortlist-frontend/translation/ssr';
import { isNonEmptyArray, useDebounceCallback } from '@sortlist-frontend/utils';
import dynamic from 'next/dynamic';
import { FC, useEffect, useState } from 'react';

import { useNavigationContext } from '_core/context/navigation-context';
import { useComparedAgencies } from '_core/hooks/use-compared-agencies';

import { ANIMATION_TIME, ComparedAgency } from '../utils';
import { SidebarProps } from './Sidebar';
import * as S from './styles';
import { trackListOpened } from './trackers';

type CompareMenuProps = {
  menu?: boolean;
};

const Sidebar = dynamic<SidebarProps>(() => import('./Sidebar').then((mod) => mod.Sidebar), {
  ssr: false,
});

export const CompareMenu: FC<CompareMenuProps> = (props) => {
  const { t } = useTranslation(['common']);
  const { query } = useNavigationContext();
  const { showMenu = false } = query;
  const { media } = useMediaQuery();

  const { comparedAgencies, setComparedAgencies } = useComparedAgencies();

  const [menuOpened, setMenuOpened] = useState<boolean>(Boolean(showMenu));
  const [animated, setAnimated] = useState<boolean>(false);

  const toggleAnimation = useDebounceCallback(() => {
    setAnimated(true);
    setTimeout(() => {
      setAnimated(false);
    }, ANIMATION_TIME);
  }, ANIMATION_TIME);

  // Jiggle on adding/removing to attract attention
  useEffect(() => {
    if (isNonEmptyArray(comparedAgencies)) toggleAnimation();
  }, [comparedAgencies]);

  const handleUncompare = (agencies: ComparedAgency[]) => setComparedAgencies(agencies);

  return (
    <>
      <S.Badge animated={animated as boolean} className="px-8">
        <MuiBadge badgeContent={comparedAgencies?.length} color="error" max={9}>
          <Tooltip title={t('common:comparator.myShortlist')} placement="bottom" arrow={true}>
            <button
              data-testid="navbar-shortlist-button"
              className="p-0 border-none bg-neutral-100 layout-column layout-align-center-center"
              onClick={() => {
                trackListOpened({ listOpened: !menuOpened });
                setMenuOpened(!menuOpened);
              }}
              id="navbar-shortlist-btn">
              <FavoriteTwoTone
                className={comparedAgencies?.length > 0 ? 'text-danger-500' : 'text-secondary-500'}
                style={{ fontSize: media.gtXs ? 20 : 24 }}
              />
            </button>
          </Tooltip>
        </MuiBadge>
      </S.Badge>
      <Sidebar
        opened={menuOpened}
        onClose={setMenuOpened}
        comparedAgencies={comparedAgencies}
        handleUncompare={handleUncompare}
      />
    </>
  );
};
