import clsx from 'clsx';
import { FC, Fragment, ReactElement, ReactNode } from 'react';

import { Obfuscate } from '_components/common/Obfuscate/Obfuscate';

type LinkWrapperProps = {
  link?: string;
  obfuscated?: boolean;
  children: ReactElement;
  wrapLi: boolean;
};

const LinkWrapper: FC<LinkWrapperProps> = (props) => {
  const { link, children, wrapLi } = props;

  const element =
    link != null ? (
      <Obfuscate obfuscate={true} target="_self" href={link}>
        {children}
      </Obfuscate>
    ) : (
      children
    );

  return wrapLi ? <li>{element}</li> : element;
};

type MobileMenuItemProps = {
  title: string;
  subtitle?: string;
  iconRight?: ReactNode;
  iconLeft?: ReactNode;
  link?: string;
  className?: string;
  dataTestid: string;
  obfuscated?: boolean;
  children?: ReactElement;
  wrapLi?: boolean;
};

export const MobileMenuItem: FC<MobileMenuItemProps> = (props) => {
  const {
    title,
    subtitle,
    iconRight,
    iconLeft,
    link,
    className,
    children,
    dataTestid,
    obfuscated,
    wrapLi = true,
  } = props;

  return (
    <Fragment>
      <LinkWrapper link={link} obfuscated={obfuscated} wrapLi={wrapLi}>
        <div
          data-testid={dataTestid}
          className={clsx(className, 'px-32 py-16 layout-row layout-align-start-center cursor-pointer')}
          aria-hidden={'true'}>
          {iconLeft}
          <div className={clsx({ 'pl-16': iconLeft }, { 'pr-16': iconRight }, 'layout-column flex')}>
            <span className="text-secondary-900 medium">{title}</span>
            {subtitle != null && <p className="small text-secondary-500">{subtitle}</p>}
          </div>
          {iconRight}
        </div>
      </LinkWrapper>
      {children}
    </Fragment>
  );
};
