'use client';

import { SearchRounded } from '@mui/icons-material';
import { FC, Fragment, ReactElement, useState } from 'react';

import { MobileMenu } from './MobileMenu/MobileMenu';

export type SearchMenuProps = {
  title: string;
  search: ReactElement;
};

export const SearchMenu: FC<SearchMenuProps> = (props) => {
  const { search, title } = props;

  const [opened, setOpened] = useState(false);

  return (
    <Fragment>
      <button
        onClick={() => setOpened(!opened)}
        aria-label="toggle-menu"
        className="bg-neutral-100 layout-column layout-align-center-center text-secondary-500 border-none p-0 m-0"
        id="toggle-menu-btn">
        <SearchRounded className="text-secondary-500" style={{ fontSize: 24 }} />
      </button>
      <MobileMenu opened={opened} toggleMenu={() => setOpened(!opened)}>
        <div className="layout-column layout-align-start-stretch" role="menu">
          <p className="h4 bold pl-32 text-secondary-900 pb-32">{title}</p>
          <div className="px-32">{search}</div>
        </div>
      </MobileMenu>
    </Fragment>
  );
};
