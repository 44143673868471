import { Logo } from '@sortlist-frontend/design-system/ssr';
import { TFunction } from '@sortlist-frontend/translation/server-only';

import { NavigationData } from '../Layout';
import { DomainsDropdown } from './DomainsDropdown/DomainsDropdown';
import { Menus } from './Menus';
import { Others } from './Others';
import { Socials } from './Socials';

export type FooterProps = {
  showRankUpdated?: boolean;
  navigationData: NavigationData;
  t: TFunction;
};

export const Footer = (props: FooterProps) => {
  const { showRankUpdated, t } = props;

  return (
    <footer id="footer" className="bg-secondary-100 p-16 pt-40 width-100">
      <div className="container-lg px-0">
        <div className="layout-column">
          <div className="px-gt-sm-16 px-8">
            <Logo className="mb-64" inversion="negative" height={32} />
          </div>
          <div className="layout-gt-sm-row">
            <div data-testid="footer-description-container" className="description-and-socials px-gt-sm-16 px-8 pr-64">
              <p className="bold lh-2">{t('common:footer.descriptionTitle')}</p>
              <p className="lh-2 text-secondary-700">{t('common:footer.description_V2')}</p>
              <Socials />
            </div>

            <Menus {...props} />
          </div>
          <div className="layout-column layout-gt-sm-row layout-align-gt-sm-space-between-center">
            <div className="flex-order-gt-sm-1">
              <DomainsDropdown {...props} />
            </div>
            <div className="p-8 p-gt-sm-16  my-16 ">
              <Others {...props} />
              {showRankUpdated ? (
                <span className="small text-secondary-500 mt-8">
                  {t('common:footer.rankingUpdated', {
                    date: new Date().toLocaleDateString('en', { day: '2-digit', month: 'long', year: 'numeric' }),
                  })}
                </span>
              ) : null}
            </div>
          </div>
        </div>
      </div>
    </footer>
  );
};
