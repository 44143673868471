import { AccountCircleTwoTone } from '@mui/icons-material';
import { Logo } from '@sortlist-frontend/design-system/ssr';

import { DomainInfo } from '_backend/integration/domain-info';
import { Menu } from '_components/common/Menu/Menu';
import { Obfuscate } from '_components/common/Obfuscate/Obfuscate';
import { getExternalResourcesUrl } from '_components/layout/utils';

import { LayoutElementsProps } from '../../Layout';
import { Explore } from './FindAgenciesMenu/Explore/Explore';
import { FindAgencies } from './FindAgenciesMenu/FindAgenciesMenu';
import { GetAdvice } from './FindAgenciesMenu/GetAdvice';
import { PostAProject } from './FindAgenciesMenu/PostAProject/PostAProject';
import { getUrlHelper } from './utils';

export const DesktopNav = (props: LayoutElementsProps) => {
  const { links, t, navigationData, search, briefingCTA, comparatorCTA, briefingAdviceCTA } = props;

  const domainInfo = DomainInfo.getFromOrigin(navigationData.origin);
  const locale = navigationData.locale;

  const getUrl = getUrlHelper(domainInfo, locale);

  return (
    <div
      data-testid="desktop-nav"
      className="hide show-gt-sm container-lg layout-fill layout-row layout-align-space-between-center">
      <div className="flex-none layout-row layout-align-start-center">
        <Obfuscate href={domainInfo?.getLocalizedUrl(locale) as string} obfuscate={true}>
          <Logo type="logotype" height={28} className="mr-16 hide-xs" />
        </Obfuscate>
        <FindAgencies
          label={t('common:navbar.menu.findAgencies_V2')}
          items={[
            {
              id: 'explore',
              title: t('common:navbar.findAgencies.explore.title'),
              subtitle: t('common:navbar.findAgencies.explore.subtitle_V2'),
              dataTestid: 'navbar-menu-explore',
            },
            {
              id: 'post-a-project',
              title: t('common:navbar.findAgencies.postAProject.title'),
              subtitle: t('common:navbar.findAgencies.postAProject.subtitle_V2'),
              dataTestid: 'navbar-menu-post-a-project',
            },
            {
              id: 'advice',
              title: t('common:navbar.findAgencies.getAdvice.title'),
              subtitle: t('common:navbar.findAgencies.getAdvice.subtitle_V2'),
              dataTestid: 'navbar-find-agencies-explore',
            },
          ]}
          getAdvice={
            <GetAdvice
              title={t('common:navbar.findAgencies.getAdvice.ourAdvisorsGuideYou_V2')}
              description={t('common:navbar.findAgencies.getAdvice.letOurIndustryExperts_V2')}
              learnMore={t('common:navbar.findAgencies.getAdvice.learnMore')}
              imgAlt={t('common:navbar.findAgencies.getAdvice.imageAlt')}
              briefingAdviceCTA={briefingAdviceCTA}
              getUrl={getUrl}
            />
          }
          postAProject={<PostAProject {...props} />}
          explore={
            <Explore
              links={links}
              navigationData={navigationData}
              title={t('common:navbar.findAgencies.explore.browseOurProviders')}
              subtitle={t('common:navbar.findAgencies.explore.searchThroughADatabase')}
              notWhatYouAreLookingFor={t('common:navbar.findAgencies.explore.notWhatYouAreLookingFor')}
              browseAllServices={t('common:navbar.findAgencies.explore.browseAllServices')}
            />
          }
        />
        <Menu
          label={t('common:navbar.menu.getClients')}
          menuClassName="menu-fix-content"
          items={[
            {
              href: getUrl('providers'),
              visible: true,
              label: t('common:navbar.getClientsMenu.apply_V2'),
              dataTestid: 'navbar-get-clients-apply',
            },
            {
              href: getUrl('providers.pricing'),
              visible: true,
              label: t('common:navbar.getClientsMenu.pricing'),
              dataTestid: 'navbar-get-clients-pricing',
            },
          ]}
        />
        <Menu
          menuClassName="menu-fix-content"
          label={t('common:navbar.menu.resources')}
          items={[
            {
              href: getExternalResourcesUrl('blog', domainInfo, locale),
              visible: domainInfo?.hasBlog() === true,
              label: t('common:navbar.resourcesMenu.blog'),
              dataTestid: 'navbar-resources-blog',
            },
            {
              href: getExternalResourcesUrl('datahub', domainInfo, locale),
              visible: domainInfo?.hasBlog() === true,
              label: t('common:navbar.resourcesMenu.datahub_v1'),
              dataTestid: 'navbar-resources-datahub',
            },
            {
              href: getUrl('stories'),
              visible: true,
              label: t('common:navbar.resourcesMenu.stories'),
              dataTestid: 'navbar-resources-stories',
            },
            {
              href: `https://help.sortlist.com/${locale}`,
              visible: true,
              label: t('common:navbar.resourcesMenu.help&Support'),
              dataTestid: 'navbar-resources-help',
            },
          ]}
        />
      </div>
      <div className="flex layout-row layout-align-end-center">
        {search}
        <div className="px-8" style={{ display: 'flex' }}>
          {comparatorCTA}
        </div>
        {briefingCTA}
        <div className="px-8 hide-gt-sm">{search}</div>
        <Menu
          label={
            <div className="layout-align-center-center layout-column">
              <AccountCircleTwoTone className="text-secondary-500" style={{ fontSize: 24 }} />
            </div>
          }
          menuClassName="menu-fix-content"
          menuStyle={{
            right: 0,
          }}
          showArrow={false}
          dataTestId="navbar-login-button"
          items={[
            {
              href: getUrl('join'),
              visible: true,
              dataTestid: 'navbar-signup',
              label: t('common:navbar.accountMenu.signup'),
            },
            {
              href: getUrl('selectLoginType'),
              visible: true,
              dataTestid: 'navbar-login',
              label: t('common:navbar.accountMenu.login'),
            },
          ]}
        />
      </div>
    </div>
  );
};
