import { Link, Links } from '_types/public-api';

import { Levels } from './_types';
import { getAttributes } from './get-attributes';

export const createLevels = (
  pageLinks: Links | undefined,
  links: Link[] | undefined,
  sort: (levels: Levels) => Levels | undefined,
): Levels | undefined => {
  if (!links || links.length === 0) return;

  const levels = links.reduce((accumulator: Levels, current) => {
    let level1 = '';
    let level2 = '';
    let level3 = '';

    if (current.attributes.metadata?.hierarchy) {
      level1 = current.attributes.metadata?.hierarchy?.[0] as string;
      level2 = current.attributes.metadata?.hierarchy?.[1] as string;
      level3 = current.attributes.metadata?.hierarchy?.[2] as string;
    } else {
      level1 = current.attributes.metadata?.level_1 as string;
      level2 = current.attributes.metadata?.level_2 as string;
      level3 = current.attributes.metadata?.level_3 as string;
    }
    const pageId = current?.relationships.to_page.data.id;

    if (typeof accumulator[level1] === 'undefined') {
      accumulator[level1] = {};
    }
    if (typeof accumulator[level1][level2] === 'undefined') {
      accumulator[level1][level2] = {};
    }
    if (typeof accumulator[level1][level2][level3] === 'undefined') {
      accumulator[level1][level2][level3] = [];
    }
    accumulator[level1][level2][level3].push({
      anchor: current?.attributes.anchor,
      link: getAttributes(pageLinks, pageId)?.path || '',
      position: current?.attributes.position,
      host: getAttributes(pageLinks, pageId)?.host || '',
      locale: getAttributes(pageLinks, pageId)?.locale || '',
      sub_anchor: current?.attributes?.metadata?.sub_anchor,
      icon: current?.attributes?.metadata?.icon_name,
    });

    return accumulator;
  }, {});
  return sort(levels);
};
