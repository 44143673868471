import { ArrowDropDownRounded, ArrowDropUpRounded, ArrowForwardRounded } from '@mui/icons-material';
import clsx from 'clsx';
import { FC, useEffect, useMemo, useState } from 'react';

import { DynamicIcon } from '_components/common/DynamicIcon';
import { IconNames } from '_core/type-utils';
import { getObjIcons } from '_core/utils/public-api/utils';
import { Levels } from '_core/utils/public-links/_types';
import { categoryFiltered } from '_core/utils/public-links/category-filtered';
import { createLevels } from '_core/utils/public-links/createLevels';
import { getLocalizedUrl } from '_core/utils/public-links/getLocalizedUrl';
import { services } from '_core/utils/public-links/services';
import { sortLevels } from '_core/utils/public-links/sort-levels';
import { Links } from '_types/public-api';

import { MobileMenu } from './MobileMenu/MobileMenu';
import { MobileMenuHeader } from './MobileMenu/MobileMenuHeader';
import { MobileMenuItem } from './MobileMenu/MobileMenuItem';

export type ExploreMenuProps = {
  opened: boolean;
  toggleMenu: () => void;
  closeExploreMenu: () => void;
  links?: Links;
  title: string;
};

export const ExploreMenu: FC<ExploreMenuProps> = (props) => {
  const { opened, toggleMenu, closeExploreMenu, links, title } = props;

  const [levelOneLinks, setLevelOneLinks] = useState<string[] | undefined>();
  const [levelOneDropdownState, setLevelOneDropdownState] = useState<string[] | undefined>();

  const exploreLinks = useMemo(
    () => createLevels(links, services(links, 'navbar', categoryFiltered), sortLevels),
    [links],
  );

  const icons = getObjIcons(links?.data, 'navbar');

  useEffect(() => {
    const levelOneLinks = Object.keys((exploreLinks || {}) as Levels).sort((a, b) => a.localeCompare(b));
    setLevelOneLinks(levelOneLinks);
  }, [exploreLinks]);

  return (
    <MobileMenu opened={opened} toggleMenu={toggleMenu} goBack={closeExploreMenu}>
      <div className="layout-column layout-align-start-stretch" role="menu">
        <MobileMenuHeader title={title} />
        <ul className="list-reset">
          {levelOneLinks &&
            levelOneLinks?.map((levelOne, key) => {
              const isOpen = levelOneDropdownState?.includes(levelOne);
              return (
                <li
                  key={`${levelOne}-${key}`}
                  onClick={(event) => {
                    event.preventDefault();
                    event.stopPropagation();
                    if (levelOneDropdownState?.includes(levelOne)) {
                      // remove from array
                      const newArray = levelOneDropdownState.filter((item) => item !== levelOne);
                      setLevelOneDropdownState(newArray);
                    } else {
                      // add to array
                      setLevelOneDropdownState([...(levelOneDropdownState || []), levelOne]);
                    }
                  }}
                  tabIndex={0}
                  onKeyDown={() => {}}
                  role="menuitem">
                  <MobileMenuItem
                    wrapLi={false}
                    dataTestid="dropdown-popular-services"
                    title={levelOne}
                    className="border-bottom border-secondary-300"
                    iconLeft={
                      <DynamicIcon
                        iconName={icons[levelOne] as IconNames}
                        className="text-secondary-500"
                        width={20}
                        height={20}
                      />
                    }
                    iconRight={
                      isOpen ? (
                        <ArrowDropUpRounded className="text-secondary-700" />
                      ) : (
                        <ArrowDropDownRounded className="text-secondary-500" />
                      )
                    }>
                    <ul className={clsx(isOpen ? 'show' : 'hide', 'list-reset')}>
                      {
                        //this object is containing levels (keys). If the key has value, the text will be displayed. if null, text we'll not be displayed
                        exploreLinks &&
                          exploreLinks[levelOne]['null']['null']
                            .sort((a, b) => a.position - b.position) // firefox is crazy, messing up arrays order => hydration issues
                            .map((levelTwo, key) => {
                              const localizedLink = getLocalizedUrl(levelTwo);
                              return (
                                <MobileMenuItem
                                  dataTestid="link-popular-service"
                                  key={`${levelTwo}-${key}-${levelTwo.anchor}`}
                                  link={localizedLink}
                                  title={levelTwo.anchor}
                                  className="border-bottom border-secondary-300 bg-secondary-100"
                                  iconRight={
                                    <ArrowForwardRounded
                                      className="arrow-forward text-secondary-500"
                                      style={{ fontSize: 16 }}
                                    />
                                  }
                                />
                              );
                            })
                      }
                    </ul>
                  </MobileMenuItem>
                </li>
              );
            })}
        </ul>
      </div>
    </MobileMenu>
  );
};
