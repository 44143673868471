import { ArrowDropDownRounded, Language } from '@mui/icons-material';
import Image from 'next/image';

import { DomainInfo } from '_backend/integration/domain-info';
import { Menu } from '_components/common/Menu/Menu';
import { SupportedLangs } from '_public/static/domainInfo/types';

import { FooterProps } from '..';
import { getCountryNameByIso, getDomainItems, getDomainUrl } from './utils';

export const DomainsDropdown = (props: FooterProps) => {
  const { navigationData, t } = props;
  const { locale, origin, resolvedUrl, languageSwitcthRedirectHome = false } = navigationData;

  const domainInfo = DomainInfo.getFromOrigin(origin);

  const iso31661 = domainInfo?.getIso31661() ? ` (${domainInfo?.getIso31661()})` : '';
  const currentLanguage = domainInfo?.getLanguages()?.filter((lang) => lang.locale === locale)[0];

  return (
    <Menu
      className="width-xs-100 width-sm-100 mt-16 mt-gt-sm-0 flex text-left"
      menuClassName="domains-menu menu-fix-content"
      buttonVariant={'raised'}
      iconLeft={<Language />}
      iconRight={<ArrowDropDownRounded style={{ fontSize: 24 }} />}
      label={`${currentLanguage?.language}${iso31661}`}
      items={getDomainItems(navigationData, domainInfo, t).map((item) => ({
        href: item.url,
        visible: true,
        className: 'menu-item',
        label: (
          <div className="small layout-row layout-align-start-center menu-item">
            {item.iso31661 != null && item.icon != null ? (
              <Image
                loading="lazy"
                className="mr-8"
                src={item.icon as string}
                alt={item.iso31661 as string}
                width="10"
                height="10"
              />
            ) : null}
            <span className="bold mr-4">{getCountryNameByIso(item?.iso31661 as string, t)}</span>(
            {item?.locales[item.domainInfoLocale as SupportedLangs]?.language})
          </div>
        ),
      }))}
    />
  );
};
