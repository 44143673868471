import styled from '@emotion/styled';
import dynamic from 'next/dynamic';

import { NAVBAR_HEIGHT } from '_components/layout/constants';
import { SearchCtaProps } from '_components/search-agencies/SearchCta';

const SearchCtaComponent = dynamic<SearchCtaProps>(
  () => import('_components/search-agencies/SearchCta').then((mod) => mod.SearchCta),
  {
    ssr: false,
  },
);

export const NavBarWrapper = styled.div`
  z-index: 98;
  min-height: ${NAVBAR_HEIGHT};
`;

export const SearchCta = styled(SearchCtaComponent)`
  max-width: 20rem !important;
`;
