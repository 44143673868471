import styled from '@emotion/styled';
import { Badge as MuiBadge } from '@mui/material';
import { COLORS } from '@sortlist-frontend/design-system';

type BadgeProps = {
  animated?: boolean;
};

export const Badge = styled.div`
  ${(props: BadgeProps) => `
    @keyframes tada {
      30%,
      50%,
      70%,
      90% {
        transform: rotate3d(0, 0, 1, 2deg);
      }

      10%,
      20%,
      40%,
      60%,
      80% {
        transform: rotate3d(0, 0, 1, -2deg);
      }
    }
    animation-name: ${props.animated ? 'tada' : 'none'};
    animation-duration: 1s;
    animation-fill-mode: both;

    .MuiBadge-badge {
      display: flex;
      flex-direction: column;
      align-items: center;
      top: 2px;
      padding: 0;
      right: -2px;
      line-height: 1.5;
      font-size: 0.625rem;
      min-width: calc(1rem + 1px);
      height: calc(1rem + 1px);
      border: 1px solid ${COLORS.NEUTRAL_100};
      background-color: ${COLORS.DANGER_500};
      line-height: 1.5;
    }
  `}
`;
