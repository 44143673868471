import { ArrowBackRounded, CloseRounded } from '@mui/icons-material';
import { Button } from '@sortlist-frontend/design-system';
import { ReactElement } from 'react';

export type MenuProps = {
  toggleMenu: () => void;
  goBack?: () => void;
  opened?: boolean;
  children: ReactElement;
};

export const MobileMenu = (props: MenuProps) => {
  const { toggleMenu, goBack, children, opened } = props;

  return (
    <div className={`mobile-menu fixed bg-neutral-100 width-100 overflow-auto ${opened ? 'opened' : ''}`}>
      <header className="layout-row layout-align-space-between-center p-16">
        {goBack && (
          <div className="flex layout-row layout-align-start-center">
            <Button
              fab
              size={'xs'}
              iconSize={'md'}
              buttonStyle={'secondary'}
              buttonVariant={'light'}
              icon={<ArrowBackRounded />}
              onClick={goBack}
              aria-label="back mobile menu"
              id="back-mobile-btn"
            />
          </div>
        )}
        <div className="flex layout-row layout-align-end-center">
          <Button
            fab
            size={'xs'}
            iconSize={'md'}
            buttonStyle={'secondary'}
            buttonVariant={'light'}
            icon={<CloseRounded />}
            onClick={toggleMenu}
            aria-label="close mobile menu"
            id="close-mobile-btn"
          />
        </div>
      </header>
      <main className="mb-96">{children}</main>
    </div>
  );
};
