import { DomainInfo } from '_backend/integration/domain-info';
import { AnchorType } from '_types/public-api';

export const getLocalizedUrl = (link: AnchorType) => {
  const linkDomainInfo = DomainInfo.getFromOrigin(`https://${link.host}`);

  return linkDomainInfo?.getMainLocale() === link.locale
    ? `https://${link.host}${link.link}`
    : `https://${link.host}/${link.locale}${link.link}`;
};

export const sortLinks = (links?: AnchorType[]) => links?.sort((a, b) => a.position - b.position) ?? [];
