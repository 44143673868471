import { LayoutElementsProps } from '../Layout';
import { DesktopNav } from './DesktopNav/DesktopNav';
import { MobileNav } from './MobileNav/MobileNav';

export const PrimaryNav = (props: LayoutElementsProps) => {
  return (
    <div className="navbar border-bottom border-secondary-300 bg-neutral-100 sticky">
      <MobileNav {...props} />
      <DesktopNav {...props} />
    </div>
  );
};
